enum NotificationTypeEnum {
  TYPE_APP_UPDATE = "app_update",
  TYPE_IMPORT_BX = "booking_experts",
  TYPE_TASK_CREATED = "task_created",
  TYPE_TASK_STATUS_CHANGED = "task_status_changed",
  TYPE_TASK_ASSIGNED = "task_assigned",
  TYPE_TASK_UNASSIGNED = "task_unassigned",
  TYPE_REPORT_CREATED = "report_created",
  TYPE_COMMENT_CREATED = "comment_created",
  TYPE_SECURITY_LOGIN = "security_login",
}

export default NotificationTypeEnum;
