<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import * as Sentry from "@sentry/vue";
import { useAuthStore } from "@verbleif/shared";
import { provideConfirmDialog } from "@verbleif/lib";
import Loader from "@/features/Loader/LoaderView.vue";
import NavbarContainer from "@/features/Navbar/NavbarContainer.vue";
import { provideLoader } from "@/features/Loader/useLoader";
import refreshToken from "@/core/refreshToken";

const route = useRoute();
const authStore = useAuthStore();
provideConfirmDialog();
const loaderProvider = provideLoader();
const hideNavbar = computed(
  () => route.path === "/" || route.meta.hideNavbar === true,
);
const isPublicRoute = computed(
  () => route.path === "/" || route.meta.public === true,
);
const globalLoading = ref(true);

watch(
  authStore.user,
  (newUser, oldUser) => {
    if (!newUser) {
      return;
    }

    if (oldUser?.id === newUser?.id) {
      return;
    }

    Sentry.setUser(authStore.user.value);
  },
);

if (!authStore.hasToken.value && !authStore.isRemembered.value) {
  authStore.setUser(null); // Reset it to be sure.
  globalLoading.value = false;
  loaderProvider.hide();
}

if (authStore.hasToken.value || authStore.isRemembered.value) {
  refreshToken()
    .finally(() => {
      globalLoading.value = false;
      loaderProvider.hide();
    });
}

const loading = computed(() => loaderProvider.loading.value);
</script>

<template>
  <transition name="fade">
    <Loader v-if="loading || globalLoading" />
  </transition>
  <template v-if="!globalLoading">
    <template v-if="!isPublicRoute && !hideNavbar">
      <NavbarContainer :class="{ 'is-visible': !hideNavbar }" class="navbar" />
      <!-- GLOBAL COMPONENTS THAT RELY ON A USER STATE SHOULD BE PLACED HERE -->
    </template>
    <div class="app-container" :class="{ 'is-login-route': !hideNavbar }">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="fade">
          <component :is="Component" />
        </transition>
        <transition name="fade">
          <VConfirmDialog />
        </transition>
      </router-view>
    </div>
  </template>
</template>

<style src="@verbleif/lib/src/scss/Reset.css"></style>

<style src="@verbleif/lib/src/scss/Fonts.css"></style>

<style src="@verbleif/lib/src/scss/Toast.css"></style>

<style lang="scss">
html {
  overflow-y: auto !important;
}

html,
body {
  letter-spacing: 0.2px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  height: 100%;
}

body {
  background: var(--background);
  --primary: #{$secondary};
  --primary-light: #{lighten($secondary, 10)};
  --primary-lighter: #{lighten($secondary, 40)};
  --primary-dark: #{darken($secondary, 10)};
  --primary-darker: #{darken($secondary, 15)};
  --secondary: #{$primary};
  --secondary-light: #{lighten($primary, 10)};
  --secondary-lighter: #{lighten($primary, 40)};
  --secondary-dark: #{darken($primary, 10)};
  --secondary-darker: #{darken($primary, 15)};
  --background: #{$light-background};
  --background-dark: #{$light-background-dark};
  --text: #{$light-text};
  --text-2: #{$light-text-2};
  --text-invert: #{$dark-text};
  --grey-1: #{$light-grey-1};
  --grey-2: #{$light-grey-2};
  --grey-3: #{$light-grey-3};
  --grey-3-dark: #{$light-grey-3-dark};
  --grey-3-darker: #{$light-grey-3-darker};
  --white: #ffffff;
  --popper-shadow: #9E9E9E;

  --input-background: #{$light-input-background};
  --input-background-hover: #{$light-input-background-hover};
  --input-background-active: #{$light-input-background-active};
  --input-text: #{$light-input-text};
  --input-placeholder: #{$light-input-placeholder};
  --spacer-bg: #{$light-spacer-bg};
  --background-modal: #ffffff;

  .dp__theme_light {
    --dp-primary-color: var(--primary);
    --dp-success-color: var(--primary);
    --dp-success-color-disabled: var(--primary-light);
    --dp-danger-color: #{$danger};
  }
}

body[data-theme="dark"] {
  --background: #{$dark-background};
  --background-dark: #{$dark-background-dark};
  --text: #{$dark-text};
  --text-2: #{$dark-text-2};
  --text-invert: #{$dark-text};
  --grey-1: #{$dark-grey-1};
  --grey-2: #{$dark-grey-2};
  --grey-3: #{$dark-grey-3};
  --grey-3-dark: #{$dark-grey-3-light};
  --grey-3-darker: #{$dark-grey-3-lighter};
  --white: rgb(255 255 255 / 10%);
  --popper-shadow: #1c1c1c;

  --input-background: #{$dark-input-background};
  --input-background-hover: #{$dark-input-background-hover};
  --input-background-active: #{$dark-input-background-active};
  --input-text: #{$dark-input-text};
  --input-placeholder: #{$dark-input-placeholder};
  --spacer-bg: #{$dark-spacer-bg};
  --background-modal: #{$dark-background};

  .theme-switch {
    .icon {
      transform: rotate(-180deg);
    }
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

mark {
  background-color: transparent;
  color: var(--primary);
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--grey-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey-2);
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 100ms ease;
}

.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(-394px);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 100ms ease;
}

.slide-down-enter-from {
  transform: translateY(-100px);
}

.navbar {
  &.is-visible {
    animation: slide-in 250ms forwards;
  }
}

.v-container {
  padding: 20px;
}

#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow-y: auto;

  .app-container {
    width: 100%;
    height: 100%;

    &.is-login-route {
      padding-left: 90px;
    }
  }
}

.Vue-Toastification__container.bottom-right {
  margin-bottom: 65px;
}

.dialog {
  .modal-card {
    background-color: white;
    border-radius: 2px;
    padding: 20px;

    .modal-card-head,
    .modal-card-foot {
      display: flex;
      justify-content: center;
      border: none;
      background-color: unset;
      padding: 0;

      p {
        text-align: center;
      }
    }

    .modal-card-body {
      background-color: unset;
      padding: 15px 10px;

      p {
        text-align: center;
      }
    }

    .modal-card-foot {
      display: flex;
      justify-content: center;
      padding: 0;
    }

    .modal-card-head {
      p {
        font-size: 1.2rem;
      }
    }
  }
}
</style>
