import type { InternalAxiosRequestConfig } from "axios";
import type { InterceptorConfig } from "@verbleif/lib";
import { InterceptorType } from "@verbleif/lib";

export function LegacyUrlPrefixPlugin(): InterceptorConfig<InterceptorType.REQUEST> {
  async function resolved(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig> {
    if (!config.url) {
      return config;
    }

    if (config.url.startsWith("/api")) {
      return config;
    }

    config.url = `/api/v1${config.url}`;

    return config;
  }

  return {
    type: InterceptorType.REQUEST,
    priority: 100,
    resolved,
  };
}
