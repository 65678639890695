import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@verbleif/shared";
import ClientsRoutes from "@/features/Clients/ClientsRoutes";
import AuthRoutes from "@/features/Auth/AuthRoutes";
import FeatureRoutes from "@/features/Features/FeatureRoutes";
import ProductRoutes from "@/features/Products/ProductRoutes.ts";

const routes = [
  ...ClientsRoutes,
  ...AuthRoutes,
  ...FeatureRoutes,
  ...ProductRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

router.beforeEach((to, _, next) => {
  if (to.meta.skipAuthChecks) {
    console.log("Skip Auth Checks");

    return next();
  }

  const authStore = useAuthStore();
  if (!authStore.hasToken.value && !authStore.isRemembered.value && !to.meta.public) {
    const redirect = to.fullPath;
    return next({ name: "login", query: { redirect }, params: { redirect } });
  }

  const fallback = to.params.redirect as string || to.query.redirect as string || { name: "clients" };
  if ((authStore.hasToken.value || authStore.isRemembered.value) && to.meta.public) {
    return next(fallback);
  }

  next();
});
