import { useAuthStore, useLocationStore } from "@verbleif/shared";
import router from "@/core/plugin/router";

export function useLogout() {
  const authStore = useAuthStore();
  const locationStore = useLocationStore();

  async function logout(redirectToLogin = true) {
    authStore.reset();
    locationStore.reset();
    localStorage.removeItem("language");

    if (redirectToLogin) {
      await router.push({ name: "login" });
    }
  }

  return { logout };
}
