import type {
  AuthResponseV1,
  AuthResponseV2,
} from "@verbleif/shared";
import type {
  HydraCollection,
  HydraItem,
  Location,
  User,
  UserV1,
} from "@verbleif/lib";
import {
  useAuthStore,
  useLocationStore,
} from "@verbleif/shared";
import * as Sentry from "@sentry/vue";
import api from "@/core/api";
import useRightsStore from "@/core/store/RightStore";

const authStore = useAuthStore();
const locationStore = useLocationStore();
const rightStore = useRightsStore();

export async function onAuthenticateSuccess(response: AuthResponseV1 | AuthResponseV2): Promise<void> {
  authStore.setToken(response.token);
  authStore.setRefreshToken(response.refresh_token);

  let userIri: string | null;
  if (typeof response.user !== "string") {
    userIri = response.user["@id"];
  } else {
    userIri = response.user;
  }

  if (!userIri) {
    console.error(response.user);
    throw new Error("No user iri could be matched.");
  }

  if (("effectiveFeatures" in response)) {
    rightStore.setEffectiveFeatures(response.effectiveFeatures);
  } else {
    rightStore.setEffectiveFeatures(response.user.effectiveFeatures);
  }

  await api.get<HydraItem<User | UserV1>>(userIri).then((r) => {
    const user = r.data;
    authStore.setUser(user);

    if (import.meta.env.PROD) {
      Sentry.setUser(user);
    }
  });

  api.get<HydraCollection<Location>>("/api/v1/locations").then((r) => {
    locationStore.setLocations(r.data["hydra:member"]);
  });
}
