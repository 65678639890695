import actions from "./actions.json";
import attachments from "./attachments.json";
import history from "./history.json";
import information from "./information.json";
import sidebar from "./sidebar.json";
import status from "./status.json";
import tasks from "./tasks.json";
import type from "./type.json";
import activity from "./activity.json";
import assignTo from "./assignTo.json";

export default {
  ...tasks,
  sidebar,
  attachments,
  history,
  type,
  information,
  actions,
  status,
  activity,
  assignTo,
};
