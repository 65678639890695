import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "@verbleif/lib/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./registerSW";
import "animate.css";
import moment from "moment";
import "@/core/config";
import "@/core/plugin/i18n";
import "@/core/plugin/router";
import "@/core/plugin/sentry";
import "@/core/plugin/fontAwesome";
import "@/core/store/RightStore";
import Toast, { POSITION } from "vue-toastification";
import Verbleif, { VToastClose } from "@verbleif/lib";
import app from "@/core/app";
import { provideUpdater } from "@/features/Updater/useUpdater";

if (import.meta.env.DEV) {
  if (window.location.hostname === "localhost") {
    window.location.hostname = "verbleif.localhost";
  }
}

moment.locale("nl");

const toastOptions = {
  closeButton: VToastClose,
  position: POSITION.BOTTOM_RIGHT,
};

app
  .use(Verbleif)
  .use(Toast, toastOptions)
  .use(provideUpdater())
  .component("VueDatePicker", VueDatePicker)
  .component("FontAwesomeIcon", FontAwesomeIcon)
  .mount("#app");
