<script setup>
import { Form as VForm } from "vee-validate";
import { useI18n } from "vue-i18n";
import { toRefs } from "vue";
import { useFeatureSchema } from "./useFeatureSchema";
import featureTypes from "./featureTypes.json";
import VField from "@/features/FormField/VField.vue";

const props = defineProps({
  manager: {
    type: Object,
    default: () => {},
  },
  item: {
    type: Object,
    default: () => {},
  },
});

const { item } = toRefs(props);
const TRANSLATION_KEY = "features";
const { t } = useI18n();
const { schema } = useFeatureSchema();
const initialValues = {
  ...item.value,
};
</script>

<template>
  <VForm
    :validation-schema="schema"
    :initial-values="initialValues"
    @submit="manager.options.onOk"
  >
    <div class="form-wrapper">
      <VField v-slot="{ field, errorMessage }" name="type">
        <VModernSelect
          v-bind="field"
          :placeholder="t(`${TRANSLATION_KEY}.type_placeholder`)"
          :label-left="t(`${TRANSLATION_KEY}.type_label`)"
          :error-message="errorMessage"
          required
          has-new-style
          key-field-name="value"
          :items="featureTypes.items"
        />
      </VField>
      <VField v-slot="{ field, errorMessage }" name="name">
        <VInput
          v-bind="field"
          has-new-style
          :error-message="errorMessage"
          :label-left="t(`${TRANSLATION_KEY}.name_label`)"
          required
          :placeholder="t(`${TRANSLATION_KEY}.name_placeholder`)"
        />
      </VField>
      <VField v-slot="{ field, errorMessage }" name="alias">
        <VInput
          v-bind="field"
          has-new-style
          :error-message="errorMessage"
          :label-left="t(`${TRANSLATION_KEY}.alias_label`)"
          required
          :placeholder="t(`${TRANSLATION_KEY}.alias_placeholder`)"
        />
      </VField>
      <VField v-slot="{ field, errorMessage }" name="description">
        <VInput
          v-bind="field"
          :error-message="errorMessage"
          has-new-style
          :label-left="t(`${TRANSLATION_KEY}.description_label`)"
          :placeholder="t(`${TRANSLATION_KEY}.description_placeholder`)"
        />
      </VField>
      <VField v-slot="{ field, errorMessage }" name="active">
        <VSwitch
          variant="is-white"
          v-bind="field"
          has-new-style
          :error-message="errorMessage"
          :label-left="t(`${TRANSLATION_KEY}.active`)"
          :true-value="1"
          :false-value="0"
        />
      </VField>
    </div>
    <div class="buttons">
      <VButton
        :loading="manager.loading.value"
        class="cancel-button"
        variant="is-light"
        @click="manager.options.onCancel"
      >
        {{ manager.options.cancelText }}
      </VButton>
      <VButton
        :loading="manager.loading.value"
        type="submit"
        class="ok-button"
        icon-left="save"
        variant="is-secondary"
      >
        {{ manager.options.okText }}
      </VButton>
    </div>
  </VForm>
</template>

<style lang="scss" scoped>
@import "../Settings/SettingsModal.scss";
</style>
