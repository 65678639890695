import { useI18n } from "vue-i18n";
import * as yup from "yup";

export function useFeatureSchema() {
  const { t } = useI18n();

  const schema = yup.object().shape({
    type: yup
      .string()
      .typeError(t("validation.required"))
      .required(t("validation.required")),
    alias: yup.string().required(t("validation.required")),
    name: yup.string().required(t("validation.required")),
    description: yup.string().nullable(),
  });

  return {
    schema,
  };
}
