import { inject, provide, ref } from "vue";
import NotificationTypeEnum from "@/features/Notification/NotificationTypeEnum";

const NotificationKey = Symbol("NotificationKey");

export const iconMap = {
  [NotificationTypeEnum.TYPE_REPORT_CREATED]: ["fas", "flag"],
  [NotificationTypeEnum.TYPE_SECURITY_LOGIN]: ["fas", "lock"],
  [NotificationTypeEnum.TYPE_TASK_ASSIGNED]: ["fas", "user"],
  [NotificationTypeEnum.TYPE_TASK_UNASSIGNED]: ["fas", "user"],
  [NotificationTypeEnum.TYPE_TASK_STATUS_CHANGED]: ["fas", "user"],
  [NotificationTypeEnum.TYPE_COMMENT_CREATED]: ["fas", "comment"],
  [NotificationTypeEnum.TYPE_TASK_CREATED]: ["fas", "ballot-check"],
  [NotificationTypeEnum.TYPE_APP_UPDATE]: ["fas", "redo"],
  [NotificationTypeEnum.TYPE_IMPORT_BX]: ["fas", "sync"],
};

export const notifications = createNotifications();

function createNotifications() {
  const notifications = ref([]);

  function addStaticNotification(notification = {}) {
    const staticNotification = notifications.value.findIndex(
      n => n.data["@id"] === notification.data["@id"],
    );

    const notificationObject = {
      ...notification,
      static: true,
      hideAnimation: true,
      hasTimer: false,
      hideExtra: true,
    };

    // If exists, update existing.
    if (staticNotification !== -1) {
      notifications.value[staticNotification] = notificationObject;
      return;
    }

    notifications.value = [notificationObject, ...notifications.value];
  }

  function hideStaticNotification(notification = {}) {
    const staticNotification = notifications.value.findIndex(
      n => n.data["@id"] === notification.data["@id"],
    );
    if (staticNotification !== -1) {
      notifications.value.splice(staticNotification, 1);
    }
  }

  function addNotification(notification = {}) {
    notifications.value = [...notifications.value, notification];
  }

  function hideNotification(index) {
    notifications.value.splice(index, 1);
  }

  return {
    hideStaticNotification,
    addStaticNotification,
    notifications,
    addNotification,
    hideNotification,
  };
}

export function provideNotifications() {
  provide(NotificationKey, notifications);

  return notifications;
}

export function useNotifications() {
  const inst = inject(NotificationKey);

  if (!inst) {
    throw new Error("Run provideNotifications before useNotifications");
  }

  return inst;
}
