import type { I18nOptions } from "vue-i18n";
import { createI18n } from "vue-i18n";
import translations from "@/translations/index";

const options: I18nOptions = {
  legacy: false,
  locale: "nl",
  messages: translations,
  numberFormats: {
    nl: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
  },
};
export const i18n = createI18n<false, typeof options>(options);
export default i18n;
export function useI18n() {
  return i18n.global;
}
