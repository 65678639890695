import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import type { Component } from "vue";
import type { ModalManager } from "@verbleif/lib";
import { api } from "@/core/api";

interface UseEditArguments {
  component: Component
  endpoint: string
  translationKey: string
  modalManager: ModalManager
  transformer?: ((input: any, item: any) => Promise<any>) | null
  load?: ((...args: any) => Promise<unknown>) | null
  props?: Record<string, any>
}

export function useEdit<T>({
  component,
  endpoint,
  translationKey,
  modalManager,
  transformer = null,
  load = null,
  props = {},
}: UseEditArguments) {
  const { t } = useI18n();
  const { success, error } = useToast();

  function showEdit(item: Record<string, any>) {
    const nonReactiveItem = JSON.parse(JSON.stringify(item));

    const name
      = typeof item.name === "object" ? item.name.join(" ") : item.name;
    modalManager.open({
      component,
      opts: {
        title: t(`${translationKey}.edit_title`, { name }),
        okIcon: ["fas", "save"],
        onOk: input => edit(input, item),
        okText: t("base.save"),
        cancelText: t("base.cancel"),
      },
      componentProps: {
        item: nonReactiveItem,
        ...props,
      },
    });
  }

  async function edit(input: Record<string, any>, item: Record<string, any>) {
    modalManager.setLoading(true);

    if (!transformer) {
      throw new Error("No transformer given.");
    }

    const output = await transformer(input, item).catch((e) => {
      modalManager.setLoading(false);
      Sentry.setContext("error", e);
      Sentry.captureException(e);
      return e;
    });

    if (output instanceof Error) {
      return;
    }

    const oldItem = Object.assign({}, item);
    Object.assign(item, output);
    success(t(`${translationKey}.edit_success`));
    modalManager.close();

    return api
      .patch<T>(`${endpoint}/${item.id}`, output)
      .then((r) => {
        Object.assign(item, r.data);
      })
      .catch((e) => {
        let msg = "base.unknown_error";
        if (e.response) {
          msg = `base.${e.response.status}`;
        }
        error(t(msg, { errorCode: 0 }));
        Object.assign(item, oldItem);
        Sentry.setContext("output", output);
        Sentry.setContext("error", e);
        Sentry.captureException(e);
      })
      .finally(() => {
        modalManager.setLoading(false);
        load && load();
      });
  }

  return {
    showEdit,
    edit,
  };
}
export default useEdit;
