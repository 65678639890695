import { useRegisterSW } from "virtual:pwa-register/vue";
import {
  notifications,
} from "@/features/Notification/useNotifications";
import NotificationTypeEnum from "@/features/Notification/NotificationTypeEnum";

const { updateServiceWorker } = useRegisterSW({
  immediate: true,
  onNeedRefresh() {
    notifications.addNotification({
      data: {
        type: NotificationTypeEnum.TYPE_APP_UPDATE,
      },
      hasTimer: false,
      buttonText: "notifications.install_update",
      buttonAction: () => {
        updateServiceWorker();
      },
    });
  },
  onRegistered(r) {
    console.log(`SW Registered: ${r}`);
    r
      && setInterval(async () => {
        console.log("Checking for sw update");
        await r.update();
      }, 300 * 1000 /* 5 min */);
  },
});
