import type { RouteRecordRaw } from "vue-router";

export const AuthRoutes = <RouteRecordRaw[]>[
  {
    name: "login-root",
    path: "/",
    component: () => import("./AuthContainer.vue"),
    children: [
      {
        path: "",
        name: "login-root",
        redirect: { name: "login" },
      },
      {
        path: "/:catchAll(.*)",
        redirect: { name: "login" },
      },
      {
        path: "login",
        name: "login",
        component: () => import("./Login/LoginView.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("./ForgotPassword/ForgotPassword.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "forgot-password/success",
        name: "forgot-password-success",
        component: () => import("./ForgotPassword/ForgotPasswordSuccess.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/:token",
        name: "reset-password",
        component: () => import("./ResetPassword/ResetPassword.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/success",
        name: "reset-password-success",
        component: () => import("./ResetPassword/ResetPasswordSuccess.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
      {
        path: "reset-password/failed",
        name: "reset-password-failed",
        component: () => import("./ResetPassword/ResetPasswordFailed.vue"),
        meta: {
          public: true,
          hideNavbar: true,
        },
      },
    ],
  },
];

export default AuthRoutes;
