import auth from "./auth";
import base from "./base.json";
import dragAndDrop from "./dragAndDrop.json";
import notifications from "./notifications";
import reports from "./reports";
import roles from "./roles.json";
import navbar from "./navbar.json";

import settings from "./settings";
import locations from "./locations";
import clients from "./clients";
import table from "./table.json";
import tasks from "./tasks";
import validation from "./validation.json";
import features from "./features.json";
import internalProducts from "./internalProducts.json";

export default {
  clients,
  base,
  roles,
  navbar,
  table,
  validation,
  locations,
  settings,
  auth,
  tasks,
  reports,
  notifications,
  dragAndDrop,
  features,
  internalProducts,
};
