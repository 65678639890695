<script setup lang="ts">
import { useField } from "vee-validate";
import { toRefs } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  rules: {
    type: Object,
    default: () => ({}),
  },
});
const { name, rules } = toRefs(props);

const {
  value: modelValue,
  setValue,
  errorMessage,
} = useField(name.value, rules.value);
</script>

<template>
  <slot
    :field="{ modelValue, 'onUpdate:modelValue': setValue }"
    :error-message="errorMessage"
  />
</template>

<style scoped></style>
