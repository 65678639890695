import { createApp } from "vue";
import App from "../App.vue";
import router from "@/core/plugin/router";
import i18n from "@/core/plugin/i18n";

const app = createApp(App);

app.use(router);
app.use(i18n);

export default app;
